const footerNavBar = {
  'footerNavBar-1': '© Pandito GmbH CH-270.4.008.394-2',
  'footerNavBar-2': '+41 77 409 1740 panditoicecream@gmail.com',
  'footerNavBar-3': 'Schaffhauserrheinweg 118, Basel. Öffnungszeiten',
  'footerNavBar-3-tooltip-1': 'Glacé-Ape',
  'footerNavBar-3-tooltip-2': 'Winterpause',
  'footerNavBar-4': 'Im Westfeld 4, Basel. Öffnungszeiten',
  'footerNavBar-4-tooltip-1': 'Gelateria',
  'footerNavBar-4-tooltip-2': 'Montag: Geschlossen',
  'footerNavBar-4-tooltip-3': 'Dienstag: Geschlossen',
  'footerNavBar-4-tooltip-4': 'Mittwoch: 12:00 - 18:00 Uhr',
  'footerNavBar-4-tooltip-5': 'Donnerstag: 12:00 - 18:00 Uhr',
  'footerNavBar-4-tooltip-6': 'Freitag: 12:00 - 18:30 Uhr',
  'footerNavBar-4-tooltip-7': 'Samstag: 11:00 - 18:30 Uhr',
  'footerNavBar-4-tooltip-8': 'Sonntag: 11:00 - 18:00 Uhr',
  'footerNavBar-5': 'Impressum / ',
  'footerNavBar-6': 'Datenschutz',
  'footerNavBar-7': 'Entworfen von Fede'
}

export default footerNavBar
