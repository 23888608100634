import { Button, Modal } from 'react-bootstrap'

const OpeningHoursModal = ({
  showFirstTimeModalHome,
  setShowFirstTimeModalHome
}) => {
  return (
    <Modal size='xl' show={showFirstTimeModalHome} onHide={() => setShowFirstTimeModalHome(false)}>
      <div id='opening-hours-image'>
        <div className='westfield-modal-main'>
          <div className='modal-info' />
          <Modal.Footer>
            <Button onClick={() => setShowFirstTimeModalHome(false)} variant='dark'>OK</Button>
          </Modal.Footer>
        </div>
      </div>
    </Modal>
  )
}

export default OpeningHoursModal
