const westfieldModalData = {
  'westfield-modal-title': 'Gelateria Pandito is OPEN!',
  'westfield-modal-body-1': 'On October 6, 2024, we finally opened the doors to our gelateria! Here, you will find homemade ice cream year-round, homemade pastries, and fine coffee and tea specialties, plus a glimpse into our production.',
  'westfield-modal-body-2': 'You can find us at Westfeld 4, 4055 Basel. You can reach us by tram line 3 to "Felix Platter-Spital" or by bus line 36 to "Felix Platter-Spital".',
  'westfield-modal-body-3': 'We look forward to your visit'
}

export default westfieldModalData

// This modal data is not rendering because the modal have been changed for a new one
// with diferent information. Leave this code lines until we know we do not need them anymore.
