const westfieldModalData = {
  'westfield-modal-title': '¡Gelateria Pandito está ABIERTA!',
  'westfield-modal-body-1': 'El 06 de octubre de 2024, finalmente abrimos las puertas de nuestra gelateria. Aquí encontrarás helado casero todo el año, pasteles artesanales y finas especialidades de café y té, además de un vistazo a nuestra producción.',
  'westfield-modal-body-2': 'Nos encontrarás en Westfeld 4, 4055 Basilea. Puedes llegar en la línea de tranvía 3 hasta "Felix Platter-Spital" o en la línea de autobús 36 hasta "Felix Platter-Spital".',
  'westfield-modal-body-3': 'Esperamos tu visita'
}

export default westfieldModalData

// This modal data is not rendering because the modal have been changed for a new one
// with diferent information. Leave this code lines until we know we do not need them anymore.
