const westfieldModalData = {
  'westfield-modal-title': 'Gelateria Pandito ist ERÖFFNET!',
  'westfield-modal-body-1': 'Wir haben am 06. Oktober 2024 endlich die Türen zu unserer Gelateria geöffnet! Hier wirst Du ganzjährig selbstgemachtes Eis, hausgemachtes Gebäck und feine Kaffee- und Teespezialitäten finden plus einen Einblick in unsere Produktion bekommen!',
  'westfield-modal-body-2': 'Im Westfeld 4, 4055 Basel findest Du uns. Du erreichst uns mit der Tramlinie 3 bis "Felix Platter-Spital" oder mit der Buslinie 36 bis "Felix Platter-Spital".',
  'westfield-modal-body-3': 'Wir freuen uns auf dein Vorbeikommen'
}

export default westfieldModalData

// This modal data is not rendering because the modal have been changed for a new one
// with diferent information. Leave this code lines until we know we do not need them anymore.
