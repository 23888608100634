import OpeningHoursModal from '../../molecules/modals/first_time_modal_home/first_time_modal_opening_hours/OpeningHoursModal'
import SliderParent from '../../organisms/SliderParent'
import { useState } from 'react'

const Home = () => {
  const [showFirstTimeModalHome, setShowFirstTimeModalHome] = useState(true)

  return (
    <div>
      <SliderParent />
      <OpeningHoursModal
        showFirstTimeModalHome={showFirstTimeModalHome}
        setShowFirstTimeModalHome={setShowFirstTimeModalHome}
      />
    </div>
  )
}

export default Home
