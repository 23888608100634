import IcecreamBigBottomImg from './OurProductsBottomImg.webp'
import IcecreamBigBottomImgOriginal from './Originals/OurProductsBottomImg.webp'
import OurIcecreamTopImg from './OurProductsTopImg.webp'
import Bags from './Bags.webp'
import Coffee from './Coffee.webp'
import Drinkeable from './Drinkeable.webp'
import IcecreamBuckets from './IcecreamBuckets.webp'
import IcecreamCone from './IcecreamCone.webp'
import IcecreamConeWithPromo from './IcecreamConeWithPromo.webp'
import IcecreamInLittleContainer from './IcecreamInLittleContainer.webp'
import Juice from './Juice.webp'
import OatBread from './OatBread.webp'
import Menu1 from './menu_1.webp'
import Menu2 from './menu_2.webp'

const backgroundImgs = [
  IcecreamBigBottomImg,
  OurIcecreamTopImg,
  IcecreamBigBottomImgOriginal
]

const productImgs = [
  { title: 'Bags', img: Bags, link: 'https://www.wickelfisch.ch/' },
  { title: 'Coffee', img: Coffee, link: null },
  { title: 'Drinkeable', img: Drinkeable, link: null },
  { title: 'IcecreamBuckets', img: IcecreamBuckets, link: null },
  { title: 'IcecreamCone', img: IcecreamCone, link: null },
  { title: 'IcecreamConeWithPromo', img: IcecreamConeWithPromo, link: null },
  { title: 'IcecreamInLittleContainer', img: IcecreamInLittleContainer, link: null },
  { title: 'Juice', img: Juice, link: null },
  { title: 'OatBread', img: OatBread, link: null }
]

const menuImgs = [
  Menu1,
  Menu2
]

export {
  backgroundImgs,
  productImgs,
  menuImgs
}
